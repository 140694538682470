import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { HomeComponent } from './home/home.component';
import { DiaryComponent } from './diary/diary.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './header/header.component';
import { UserService } from './shared/user.service';
import { AuthGuard } from './auth/auth.guard';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WebcamModule } from 'ngx-webcam';
import { ChartsModule } from 'ng2-charts';
import { NgCircleProgressModule } from 'ng-circle-progress';


@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    SignUpComponent,
    SignInComponent,
    HomeComponent,
    DiaryComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    HttpClientModule,
    FormsModule,
    ToastrModule.forRoot({
      maxOpened: 2
    }),
    BrowserAnimationsModule,
    WebcamModule,
    ChartsModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      showSubtitle: false,
      titleColor: "#FFFFFF",
      unitsColor: "#FFFFFF",
      titleFontSize: '15',
      radius: 20,
      outerStrokeWidth: 4,
      outerStrokeColor: "#FDCE08",
      animationDuration: 300,
      innerStrokeWidth: 2,
      innerStrokeColor: '#b1a885',
      space: -3
    })
  ],
  providers: [
    UserService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
