import { Component, OnInit } from '@angular/core';
import { User } from './../../shared/user.model';
import { NgForm } from '@angular/forms';
import { UserService } from './../../shared/user.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

    user: User;
    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    access_token: string;
    user_id = null;

    constructor(private _userService: UserService, private _toastr: ToastrService, private _router: Router) { }

    ngOnInit() {
        this.resetForm();
    }

    resetForm(form?: NgForm) {
        if (form != null) {
            form.reset();
        }
        this.user = {
            userName: '',
            email: '',
            password: ''
        };
    }

    OnSubmit(form: NgForm) {
        this._userService.registerUser(form.value).subscribe((data: any) => {
            this.resetForm(form);
            this._toastr.success('Sign Up Successful', 'Tada!');
            this._router.navigate(['/login']);
        },
            (err: HttpErrorResponse) => {
                this._toastr.error(err.error.error.message, err.error.error.code);
            });
    }
}
